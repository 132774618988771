<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Rates per person') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mini-gap is-mobi">
          <div class="column is-3">
            <Filter
              class="per-person-filter"
              :steps="steps"
              @clear-filter="resetList"
              @filter="fetchFromQueries"
            />
          </div>
          <div class="column">
            <a-spin v-if="dates && canFetch()" :spinning="isFetching">
              <div v-show="!isFetching" class="price-tables">
                <a-button
                  class="page-arrows"
                  type="link"
                  @click.prevent.stop="fetchPreviousDate()"
                >
                  <CaretUpOutlined />
                  <span class="page-arrows__label">
                    {{ $filters.date(previousDate, { format: 'MM/DD' }) }}
                  </span>
                </a-button>
                <table v-for="date in dates" :key="date" class="price-table">
                  <thead>
                    <th class="price-table__title">
                      {{ $filters.date(date, { format: 'MM/DD' }) }}
                    </th>
                    <th>{{ $t('Pax 1') }}</th>
                    <th>{{ $t('Pax 2') }}</th>
                    <th>{{ $t('Pax 3') }}</th>
                    <th>{{ $t('Pax 4') }}</th>
                    <th>{{ $t('Pax 5') }}</th>
                    <th>{{ $t('Pax 6') }}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="price-table__title">
                        {{ $t('Neppan Price') }}
                      </td>
                      <td>{{ rates.neppan[date][PAX1] }}</td>
                      <td>{{ rates.neppan[date][PAX2] }}</td>
                      <td>{{ rates.neppan[date][PAX3] }}</td>
                      <td>{{ rates.neppan[date][PAX4] }}</td>
                      <td>{{ rates.neppan[date][PAX5] }}</td>
                      <td>{{ rates.neppan[date][PAX6] }}</td>
                    </tr>
                    <tr>
                      <td class="price-table__title">
                        {{ $t('PIOP Price') }}
                      </td>
                      <td>{{ rates.piop[date][PAX1] }}</td>
                      <td>{{ rates.piop[date][PAX2] }}</td>
                      <td>{{ rates.piop[date][PAX3] }}</td>
                      <td>{{ rates.piop[date][PAX4] }}</td>
                      <td>{{ rates.piop[date][PAX5] }}</td>
                      <td>{{ rates.piop[date][PAX6] }}</td>
                    </tr>
                    <tr>
                      <td class="price-table__title">
                        {{ $t('Registering Price') }}
                      </td>
                      <td>
                        <a-input
                          v-model:value="form.rates[date][PAX1]"
                          type="number"
                          class="price-input has-text-right"
                          size="large"
                          :min="0"
                        />
                      </td>
                      <td>
                        <a-input
                          v-model:value="form.rates[date][PAX2]"
                          type="number"
                          class="price-input has-text-right"
                          size="large"
                          :min="0"
                        />
                      </td>
                      <td>
                        <a-input
                          v-model:value="form.rates[date][PAX3]"
                          type="number"
                          class="price-input has-text-right"
                          size="large"
                          :min="0"
                        />
                      </td>
                      <td>
                        <a-input
                          v-model:value="form.rates[date][PAX4]"
                          type="number"
                          class="price-input has-text-right"
                          size="large"
                          :min="0"
                        />
                      </td>
                      <td>
                        <a-input
                          v-model:value="form.rates[date][PAX5]"
                          type="number"
                          class="price-input has-text-right"
                          size="large"
                          :min="0"
                        />
                      </td>
                      <td>
                        <a-input
                          v-model:value="form.rates[date][PAX6]"
                          type="number"
                          class="price-input has-text-right"
                          size="large"
                          :min="0"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <a-button
                  class="page-arrows"
                  type="link"
                  @click.prevent.stop="fetchNextDate()"
                >
                  <CaretDownOutlined />
                  <span class="page-arrows__label">
                    {{ $filters.date(nextDate, { format: 'MM/DD' }) }}
                  </span>
                </a-button>
              </div>
            </a-spin>
            <a-empty v-else>
              <template #description>
                <span>{{ $t('no data') }}</span>
              </template>
            </a-empty>
          </div>
        </div>
        <div class="columns is-mini-gap is-mobi is-multiline">
          <div class="column has-text-right is-12 m-t-4x">
            <a-checkbox v-model:checked="form.applyToAllRoom">
              {{ $t('Apply changes to all rooms') }}
            </a-checkbox>
          </div>
          <div class="column has-text-right is-12 m-t-2x">
            <a-button
              :disabled="loading"
              :size="size"
              class="button-secondary-outline m-r-3x"
              style="min-width: 115px"
              @click="handleExportCsv"
            >
              {{ $t('Export to CSV') }}
            </a-button>
            <a-button
              :loading="loading"
              :size="size"
              class="m-r-3x"
              style="min-width: 115px"
              type="primary"
              @click="handleToNeppan"
            >
              {{ $t('Submit to Neppan') }}
            </a-button>
            <a-button
              :loading="loading"
              :size="size"
              style="min-width: 115px"
              type="primary"
              @click="handleSave"
            >
              {{ $t('Save') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Rates per person": "Rates per person",
    "no data": "Please select property, room, and plan name",
  },
  "ja": {
    "Rates per person": "１人あたりの料金",
    "no data": "物件、部屋、プラン名を選択してください",
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons-vue';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';
import { getResponseError } from '@/utils/util';
import { RATE_PAX, RATE_TYPES } from '@/config/revenue-managements';
import Filter from './components/Filter';

export default {
  name: 'RatesPerPerson',
  components: {
    CaretUpOutlined,
    CaretDownOutlined,
    Filter,
  },
  data() {
    return {
      size: 'large',
      isFetching: false,
      form: {
        applyToAllRoom: false,
        rates: {},
      },
      rates: {
        neppan: {},
        piop: {},
      },
      dates: [],
      isRenderTable: true,
      steps: 2,
      PAX1: RATE_PAX.PAX1,
      PAX2: RATE_PAX.PAX2,
      PAX3: RATE_PAX.PAX3,
      PAX4: RATE_PAX.PAX4,
      PAX5: RATE_PAX.PAX5,
      PAX6: RATE_PAX.PAX6,
    };
  },
  computed: {
    previousDate() {
      const dates = this.dates || [];

      return dayjs(dates[0]).subtract(1, 'day').format('YYYY-MM-DD');
    },
    nextDate() {
      const dates = this.dates || [];

      return dayjs(dates[dates.length - 1]).add(1, 'day').format('YYYY-MM-DD');
    },
  },
  async created() {
    await this.fetchFromQueries();
  },
  methods: {
    async fetchNextDate() {
      if (!this.canFetch()) {
        return;
      }

      const { query } = this.$route;
      const { nextDate } = this;

      await this.fetchData([nextDate]);

      this.dates.push(nextDate);

      await this.$router.push({
        query: {
          ...query,
          dateTo: nextDate,
        },
      });
    },
    async fetchPreviousDate() {
      if (!this.canFetch()) {
        return;
      }

      const { query } = this.$route;
      const { previousDate } = this;

      await this.fetchData([previousDate]);

      this.dates.unshift(previousDate);

      await this.$router.push({
        query: {
          ...query,
          dateFrom: previousDate,
        },
      });
    },
    async fetchFromQueries() {
      if (!this.canFetch()) {
        return;
      }

      const { dateFrom, dateTo } = this.$route.query;
      const dates = this.getDates(dayjs(dateFrom), dayjs(dateTo));

      await this.fetchData(dates);

      this.dates = dates;
    },
    canFetch() {
      const {
        propertyId,
        roomCode,
        plan,
      } = this.$route.query;

      return propertyId && roomCode && plan;
    },
    async fetchData(dates = []) {
      this.isFetching = true;

      try {
        const result = await Promise.all([
          this.fetchNeppanRates(dates),
          this.fetchPiopRates(dates),
          this.fetchRegisteredRates(dates),
        ]);

        return result;
      } finally {
        this.isFetching = false;
      }
    },
    async fetchNeppanRates(dates = []) {
      // temp
      for (let i = 0; i < dates.length; i += 1) {
        const date = dates[i];

        if (!this.rates.neppan[date]) {
          this.rates.neppan[date] = {
            [this.PAX1]: 0,
            [this.PAX2]: 0,
            [this.PAX3]: 0,
            [this.PAX4]: 0,
            [this.PAX5]: 0,
            [this.PAX6]: 0,
          };
        }
      }
    },
    async fetchPiopRates(dates = []) {
      // temp
      for (let i = 0; i < dates.length; i += 1) {
        const date = dates[i];

        if (!this.rates.piop[date]) {
          this.rates.piop[date] = {
            [this.PAX1]: 0,
            [this.PAX2]: 0,
            [this.PAX3]: 0,
            [this.PAX4]: 0,
            [this.PAX5]: 0,
            [this.PAX6]: 0,
          };
        }
      }
    },
    async fetchRegisteredRates(dates = []) {
      const query = {
        ...this.$route.query,
        dateFrom: dates[0],
        dateTo: dates[dates.length - 1],
        type: RATE_TYPES.PERSON,
      };

      const { data } = await this.$store.dispatch('rms-rates/list', query);

      const rates = {};

      for (let i = 0; i < data.length; i += 1) {
        const { date, price, type } = data[i];

        if (!rates[date]) {
          rates[date] = {};
        }

        rates[date][type] = price;
      }

      // fill price textboxes
      for (let j = 0; j < dates.length; j += 1) {
        const date = dates[j];
        if (!this.form.rates[date]) {
          this.form.rates[date] = {};
        }

        this.form.rates[date] = {
          [this.PAX1]: rates[date]?.[this.PAX1] || this.calculateRegisteringPrice(),
          [this.PAX2]: rates[date]?.[this.PAX2] || this.calculateRegisteringPrice(),
          [this.PAX3]: rates[date]?.[this.PAX3] || this.calculateRegisteringPrice(),
          [this.PAX4]: rates[date]?.[this.PAX4] || this.calculateRegisteringPrice(),
          [this.PAX5]: rates[date]?.[this.PAX5] || this.calculateRegisteringPrice(),
          [this.PAX6]: rates[date]?.[this.PAX6] || this.calculateRegisteringPrice(),
        };
      }
    },
    calculateRegisteringPrice() {
      // calculate the price "PIOP price" * "Rate Calculation Base Pax"
      return 0;
    },
    getDates(from, to) {
      const dates = [];
      let currentDate = from;

      while (currentDate <= to) {
        dates.push(dayjs(currentDate).format('YYYY-MM-DD'));
        currentDate = dayjs(currentDate).add(1, 'day');
      }

      return dates;
    },
    async handleSave() {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        const { query } = this.$route;
        const payload = {
          propertyId: query.propertyId,
          rates: [],
        };

        const { rates } = this.form;
        const base = {
          roomCode: query.roomCode,
          plan: query.plan,
        };
        const dates = Object.keys(rates) || [];

        for (let i = 0; i < dates.length; i += 1) {
          const date = dates[i];
          const types = Object.keys(rates[date]);

          for (let j = 0; j < types.length; j += 1) {
            const type = types[j];
            payload.rates.push({
              ...base,
              date,
              type,
              price: Number(rates[date][type]),
            });
          }
        }

        await this.$store.dispatch('rms-rates/saveByProperty', payload);
      } catch (error) {
        this.$message.error(getResponseError(error) || 'Error');
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    handleExportExcel() {
      const params = {
        ...this.$route.query,
        locale: getLocale() || getDefaultLang(),
      };

      this.$store
        .dispatch('rms-rates/exportCsv', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    resetList() {
      this.rates = {
        neppan: {},
        piop: {},
      };
      this.dates = [];
      this.columns = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.price-input::v-deep {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.price-tables::v-deep {
  margin-left: 0.5rem;

  .page-arrows {
    padding: 10px 0;
    margin-bottom: 10px;

    .page-arrows__label {
      color: $black;
      font-weight: normal;
    }
  }
}

.price-table::v-deep {
  th {
    background-color: $tab-head-bg;
    color: $white;
    padding: 5px 16px;
  }

  td {
    padding: 16px;
  }

  th,
  td {
    text-align: right;
    padding-right: 24px;
  }

  th.price-table__title,
  td.price-table__title {
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    border-right: 1px solid #DCDCDC;
    white-space: nowrap;
  }

  tr:nth-child(even) {
    background-color: #F5F5F5;
  }
}
</style>
